import React from 'react';
import { ApolloClient, createHttpLink, InMemoryCache, ApolloProvider } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const httpLink = createHttpLink({
    uri: 'http://185.112.144.96:1337/graphql',
  });
  
  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = "7959673acc8febcb0ed741a3d5443d34c241298145507863e26da10924e1090301b280b76833f449bfb05c419480edb54c2c312ff06bac2005713db7398d6fba9316099ed93ba9719c6a87cea9adb7af77fb3a0f4b14245d603bed54307401b42ddae7492c70f57c2210fc5a3e82127a076bc82bab357557d93966b6ffcff684";
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      }
    }
  });

const client = new ApolloClient({
    link: authLink.concat(httpLink),
     cache: new InMemoryCache(),
});
const ApolloAppProvider = ({ children }) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
export default ApolloAppProvider;