import './App.css';

import GetEmployees from "./Components/GetEmployees"
import ApolloAppProvider from './ApolloProvider';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SiteHeader from "./Components/SiteHeader"
import Home from './Pages/Home';
import Employees from './Pages/Employees';
import Employee from './Pages/Employee';
import About from './Pages/About';
import Bottom from './Pages/Bottom';
import Banner from './Components/Banner';

function App() {
  console.log('I was triggered during render')
  return ( <ApolloAppProvider><Router>
    <div className="Apps">
      <SiteHeader/>
      <div className="background">
        <Banner/> 
      <div className="container">
      <Routes>
        <Route path="/" element={<Home />} />       
        <Route path="/Employees" element={<Employees />}/>
        <Route path="/Employee/:id" element={<Employee />}/>
        <Route path="/About" element={<About />}/>
        <Route path="/About/:id" element={<About />}/>
      </Routes></div>
      <Bottom></Bottom></div> </div>
      </Router></ApolloAppProvider>
  );
}

export default App;
