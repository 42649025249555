import React, { useEffect, useState } from 'react'
import  { Link } from 'react-router-dom'
import './styles.css'

export default function Employee({attributes}) {
    const [uri, seturi] = useState();
    useEffect
    (()=>{  
      if(attributes.Image.data) seturi("http://185.112.144.96:1337"+attributes.Image.data.attributes.url)
    },[attributes.Image]) 
  
    
  return (
  
      <div className='empCard'>
        <div className='imgBox'>{uri?<img  src={uri+"?w=200"}/>:<></> }</div>
        <div className='empDetail'>
          <div className='empName'>{attributes.Name}</div>
          <div>{attributes.Title}</div>
          <div>{attributes.Email}</div>
          <div className='ruler'></div>        
        </div>      
      </div>
  
  )
  //<Link to={"/employee/"+name.replaceAll(' ', '-')}>more... </Link>
}
