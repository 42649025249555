import React, { useState, useEffect, useContext} from 'react'
import sanityClient from "../client";
import Employee from "../Components/Employee"
import GlobalContext from '../state/GlobalContext';
import { motion } from "framer-motion"
import { gql, useQuery } from '@apollo/client';
const GET_USERS = gql`
  query GetUsers {
   employees  (pagination: {limit:50}) {data {id, attributes {Name, Tags, Title, Image{data{attributes{url}}}}}}
  }
`;

export default function Employees() {
    const {state, dispatch} = useContext(GlobalContext);
    const { loading, error, data } = useQuery(GET_USERS);
  
    useEffect(() => {
        if(loading) return;     

      var tags =[];
      if(data.employees.data){
        console.log(data.employees.data);
      data.employees.data.map(filteredName => {   
        if(filteredName.attributes.Tags){
          filteredName.attributes.Tags.forEach(element => {
            console.log(element.name)     ;
              tags.push(element.name)
          })
          
      }});
        tags.push("... Allt starfsfólk")
        var list = tags.filter((x, i, a) => a.indexOf(x) == i).sort()
      }   
      dispatch({type: "ADD_FILTERS", payload: list});
      dispatch({type: "ADD_PAGE_TITLE", payload: "Starfsfólk"});
      dispatch({type: "ADD_EMPLOYEES", payload: data.employees.data});
      dispatch({type: "FILTERED_EMPLOYEES", payload: data.employees.data});
	}, [data]);
     console.log(data);
     console.log(state.filteredEmployees);
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

      

    if(!state.filteredEmployees) return <div>loading.....</div>
    if (state.filteredEmployees) {
      
        return <><div  className='flex-tags '>{state.filters?state.filters.map((val)=>
            {
               
                return <div key={val} className={`tagsBlock ${state.filter === val ? "tagsBlockselected" : ""}`} onClick={
                    ()=>{
                        dispatch({type: "CHANGE_FILTER", payload: val});
                        if (val==="... Allt starfsfólk" ){
                            dispatch({type: "FILTERED_EMPLOYEES", payload: state.employees});
                        }
                        else{
                          console.log(val)
                        var list = state.employees.filter(function (el) {
                          
                            return el.attributes.Tags && el.attributes.Tags.find( c => c.name === val );                           
                        }) 
                        dispatch({type: "FILTERED_EMPLOYEES", payload: list});
                    }
                        
                    }}
                >{val}</div>
            }):<></>}</div>
           
            <div className='flex-container '>{state.filteredEmployees?state.filteredEmployees.map((val)=>{
              console.log(val);
            return <motion.Employee
            initial={{ opacity: 0}}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.5 }}
          ><Employee key={val.id} {...val}></Employee>ff</motion.Employee>
        }):<></>}</div></>
    }
}
