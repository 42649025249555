import React, { useContext, useState } from 'react'
import { Routes, Route, Link, useParams, useLocation } from "react-router-dom"

import './styles.css'
import GlobalContext from '../../state/GlobalContext';

export default function Banner() {
  const {state} = useContext(GlobalContext);
  const location = useLocation()
  return (
  
    <div className='banner'  style={location.pathname!=="/" ? { height:200, position: 'sticky'} : {}}>
      <div class="layer">
      
      <div className='bannerTitle'>{state.pageTilef}</div></div></div> 
  
  )
}
