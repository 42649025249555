import React, {useState, useEffect, useContext} from 'react'
import { useParams } from 'react-router-dom'
import { motion } from "framer-motion"


import GlobalContext from '../state/GlobalContext';



export default function Employee() {

  return <></>
  /*const {state, dispatch} = useContext(GlobalContext);
    const {id}  = useParams();

    const [emp, setEmp] = useState(null);
    if(!emp) return <div>loading</div>
   
    if (emp) {
        console.log(emp)
        return  <motion.div
        initial={{ opacity: 0}}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
      ><div className='parent'><div className='EmpContainer'><div className='empDetailName'>{emp.name}</div><div>{emp.email}</div>
          <div className='EmpDetailContainer'>
          <motion.div
        initial={{ x: "-100%"}}
        animate={{  x:0}}
        transition={{ duration: 1.5 }}
      >
            <div  id="sliderP" class="slide-inP"><img src={emp.image.asset.url+"?w=400"} alt="img"/></div>  </motion.div>
            <div  className='EmpDetailBio'><motion.div
        initial={{ x: "+100%"}}
        animate={{  x:0}}
        transition={{ duration: 1.5 }}
      >
            </motion.div> </div>
            </div></div></div>
            </motion.div>
    }
            */
}