
import React,{useState} from 'react'
import  { Link } from 'react-router-dom'
import { IoMenuSharp } from "react-icons/io5";
import logo from '../../images/eflingLogo.png';
import './styles.css'

export default function SiteHeader() {
  const [style, setStyle] = useState();
  return (
    <header >
      <div >
      <a href="#" className="efbox"> <img src={logo} className="efbox-img" alt="logo" /></a></div>
      <div className='Nav'>
        <ul  >
          <li><Link to="/">Home</Link></li>
          <li><Link to="/Employees"   >Starfsmenn</Link></li>
          <li><Link to="/About">Um Eflingu</Link></li>
          <li><Link to="/Employees">Þjónusta</Link></li>
          <li><Link to="/Employees">Vörur</Link></li>
        </ul></div>
        <IoMenuSharp
        className='menuToggle' onClick={ddd => {
          if (style) {
            setStyle('');
            console.log(style)
          } else {
            setStyle('open')
            console.log(style)
          }
        }} 
/>
</header>
  )
}
