import React from 'react'
import ReactMarkdown from 'react-markdown';
import foot from "../images/efFooter.png"

export default function Bottom() {
    console.log("loading.....")
       return <div  id="footer">
        <div id="footerFlex">
            <div><img src={foot}/></div>
            <div>Hafnastrpæti</div>
            <div>Hvar erum við?<br/>
                Hafnastræti<br/>
                kaupangur<br/>
                4662223<br/>
                afgreidsla@eflingehf.is<br/>
                opnunartími: 9-17<br/>
            </div>
            </div>
            
            
        </div>

   
 }
