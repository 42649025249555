import React, { useState, useEffect} from 'react'
import { useParams , Link} from 'react-router-dom'
import sanityClient from "../client";
import GlobalContext from '../state/GlobalContext';
import {getAboutUs, getAboutArtice} from "../GraphQL/Queries"

const BlockContent = require('@sanity/block-content-to-react')



export default function Employees() {
    const [arctices, setArctices] = useState(null);
    const [selected, setSelected] = useState(null);
    const [selectedArctID, setSelectedArctID] = useState('Um okkur');
    const {id}  = useParams();
    useEffect(() => {   
    if(id){
        setSelectedArctID(id)
    }
}, [id]);
  

    useEffect(() => {
        const fetchData = async () => {
            const data = await getAboutUs();
            setArctices(data);
          }
        
          // call the function
          fetchData()
            .then(console.log('fetched'))
            .catch(console.error);;

            
	}, []);
    useEffect(() => {
        const fetchData = async () => {
            const data = await getAboutArtice(selectedArctID.replaceAll('-', ' '));
            setSelected(data);
          }
        
          // call the function
          fetchData()
            .then(console.log('fetched'))
            .catch(console.error);
 }, [selectedArctID]);

    if(!arctices) return <div>loading.....</div>
    if (selected) {
         return   <div className='flex-container '><div><h1>{selected.title}</h1>
         <BlockContent blocks={selected.story}  />
         </div>
        <ul>{arctices?arctices.map((val)=>{
            return <li key={val._id} ><Link to={"/About/"+val.title.replaceAll(' ', '-')}>{val.title}</Link></li>
        }):<></>}</ul>
        
        </div>
    }
}
