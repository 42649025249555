import sanityClient from "../client";


export async function getAboutUs() {
  const posts = await sanityClient.fetch(`*[_type == "about" ]
  { title,
    _id
        }`)
  return posts
}
export async function getAboutArtice( id) {
  const posts = await sanityClient.fetch(`*[_type == "about" && title=="${id}"][0]
  { title,
    _id,
    story
        }`)
  return posts
}